.App {
  text-align: center;
}

.App-logo {
  height: 60px;
  pointer-events: none;
}
@media (max-width: 768px) {
  .quicklinks {
    grid-template-columns: 1fr;
    
  }
  .side {
    grid-template-columns: 1fr;
    background-color: white;
    align-items: center;
    display: grid;
    color: black;
    padding: 5px;
    font-size: x-large;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .quicksection {
    margin-bottom: 10px;
    flex-direction:column;
    text-align:center;
    flex-direction:column;
    align-items:center;
    display:flex;
  }

  .image {
    width: 100%;
    max-width: none; 
    height: auto; 
  }
}
@media (min-width: 768px) {
  .quicklinks {
    grid-template-columns: 1fr 1fr 1fr;
    background-color: white;
    align-items: center;
    display: grid;
    color: black;
    padding: 10px;
    font-size: x-large;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .side {
    grid-template-columns: 3fr 1fr;
    background-color: white;
    align-items: center;
    display: grid;
    color: black;
    padding: 10px;
    font-size: x-large;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .quicksection {
    animation: fadeIn 2s ease-in forwards;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  background-color: white;
  justify-content: center;
  align-items: center;
  display: grid;
  color: black;
  padding: 10px;
  font-size: x-large;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.dropdown-item{
  background-color:black;
  color:white;
  outline:none;
  text-align:center;
}
.navbar-nav .nav-link {
  color: lightgray;
}
.nav {
  background-color: black;
  color:white;
  padding: 10px;
  text-align: center;
}
.custom-dropdown .dropdown-toggle {
  background-color: black; 
  border: none;
  color: white;
  box-shadow: none; 
  outline: none; 
}
.bar {
  background-color:black;
  color:white;
  display: inline;
  outline: none;
  padding:10px;
}
.nav a {
  font-size: x-large;
  color: lightgray;
  float: middle;
  padding: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-decoration: none;
}
.dropdown-menu {
  background-color:black;
  color:white;
  z-index: 999; 
  text-align:center;
}
.dropdown-toggle:focus {
  box-shadow: none !important;
  color: white;
}

.nav a:hover {
  background-color:black;
  color: white;
}

.image {
  float: right;
  width: 27%; 
  padding: 7px;
  margin-top: 65px;
}

.projectlink {
  font-size: large;
  text-decoration: none;
  color: black;
}

.headshot {
  width: 200px;
  max-width: 100%;
  max-height: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.text-fade-in {
  text-align: center;
  float: center;
  font-size: xx-large;
  width: 100%;
  size: 200%;
  animation: fadeIn 2s ease-in forwards;
}

.text {
  text-align: center;
}

.image {
  float: center;
  height: 450px;
  width: auto;
  overflow: hidden;
}

.footer {
  background-color: black;
  padding: 10px;
  text-align: center;
  color: white;
  margin-top: auto;
  width: 100%;
  height: 2.5rem;
}
.container {
  z-index:1;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding:10px;
}

.contentwrap {
  flex: 1;
  padding-bottom: 5.5rem;
  z-index: 0;
}


.small {
  width: 250px;
}
.medium{
  width:350px;
}

.center {
  text-align: center;
}